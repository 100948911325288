import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ImageCaption from './ImageCaption';

const Image = ( { imgName, style, imgStyle, placeholderStyle, caption } ) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      if ( !imgName ) {
        return null
      }
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === imgName.replace( /^.*[\\/]/, '' )
      );
      if ( !image ) {
        return null
      }
      return <>
        <Img
          fluid={image.node.fluid}
          style={style}
          imgStyle={imgStyle}
          placeholderStyle={placeholderStyle}/>
        <ImageCaption caption={caption}/>
      </>
    }}
  />
);

export default Image;
