import React, { Component } from 'react';
import styled from 'styled-components';

const Caption = styled.p`
  text-align: right;
`;

class ImageCaption extends Component {
    render() {
      if( this.props.caption ) {
        return (
          <Caption><small>{this.props.caption}</small></Caption>
        )
      } else {
        return null;
      }
    }
}

export default ImageCaption;